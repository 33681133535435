'use client';

import React from 'react';
import classNames from 'classnames';
import type { ImageProps } from 'next/image';
import Image from 'next/image';
import { optimizeContentfulImageUrl } from '@/util/contentfulImages';
import type { CloudinaryProps } from './cloudinary';
import { cloudinaryLoader, getCloudinarySrc } from './cloudinary';

export interface ImgProps extends ImageProps, CloudinaryProps {
  readonly provider?: 'cloudinary' | 'contentful';
}

export function Img({
  className,
  format,
  src,
  provider,
  alt,
  width,
  height,
  ...props
}: ImgProps): JSX.Element {
  const providerProps: Pick<ImageProps, 'src' | 'loader' | 'unoptimized'> = { src };
  if (provider === 'cloudinary' && typeof src === 'string') {
    providerProps.src = getCloudinarySrc(src);
    providerProps.loader = cloudinaryLoader({ format });
  } else if (provider === 'contentful' && typeof src === 'string') {
    const contentfulSrc = optimizeContentfulImageUrl(
      src,
      typeof width === 'number' ? width : undefined,
      typeof height === 'number' ? height : undefined
    );
    providerProps.src = contentfulSrc;
    providerProps.unoptimized = true;
    providerProps.loader = () => contentfulSrc;
  }

  return (
    <Image
      className={classNames(Img.displayName, className)}
      alt={alt}
      width={width}
      height={height}
      {...providerProps}
      {...props}
    />
  );
}

Img.displayName = 'Img';

export default Img;
