import { captureException } from '@sentry/nextjs';

export const CONTENTFUL_IMAGE_HOSTNAME = 'images.ctfassets.net';

/**
 * Transforms a Contentful image URL by adding quality and format parameters.
 * Optionally accepts width and/or height parameters to resize the image.
 * A custom logger function can be provided as the last argument to replace the default captureException.
 * Returns original URL if parameters are invalid to ensure an image is always returned.
 * Returns original URL unchanged if the image is an SVG.
 * @param url The original Contentful image URL
 * @param args Rest parameters that can include:
 *   - width (number): Optional width to resize the image
 *   - height (number): Optional height to resize the image
 *   - logger (Function): Optional custom logger function that accepts a message and additional arguments
 * @returns The transformed URL with added parameters, or original URL if invalid
 * @example
 * // Basic usage
 * optimizeContentfulImageUrl('https://images.ctfassets.net/abc/image.jpg')
 * // With dimensions
 * optimizeContentfulImageUrl('https://images.ctfassets.net/abc/image.jpg', 800, 600)
 * // With custom logger
 * optimizeContentfulImageUrl('https://images.ctfassets.net/abc/image.jpg', customLogger)
 * // With width and logger
 * optimizeContentfulImageUrl('https://images.ctfassets.net/abc/image.jpg', 800, customLogger)
 */
export const optimizeContentfulImageUrl = (
  url: string,
  ...args: (number | undefined | ((...args: any[]) => void))[]
): string => {
  const width = typeof args[0] === 'number' ? args[0] : undefined;
  const height = typeof args[1] === 'number' ? args[1] : undefined;
  let logger: (...args: any[]) => void = captureException;
  if (typeof args[0] === 'function') {
    // @NOTE for readability
    // eslint-disable-next-line prefer-destructuring
    logger = args[0];
  } else if (typeof args[1] === 'function') {
    // @NOTE for readability
    // eslint-disable-next-line prefer-destructuring
    logger = args[1];
  } else if (typeof args[2] === 'function') {
    // @NOTE for readability
    // eslint-disable-next-line prefer-destructuring
    logger = args[2];
  }
  let parsedUrl;

  try {
    // Try parsing the URL, return original if invalid
    parsedUrl = new URL(url);
  } catch (error) {
    // @TODO: Log error
    logger('Failed to parse Contentful image URL', { url, error });
    return url;
  }
  // Validate it's a Contentful URL
  if (!parsedUrl.hostname.includes(CONTENTFUL_IMAGE_HOSTNAME)) {
    logger('Invalid Contentful image URL', { url });
    return url;
  }

  if (parsedUrl.pathname.endsWith('.svg')) {
    return url;
  }

  // Add width parameter
  if (width) {
    parsedUrl.searchParams.append('w', width.toString());
  }

  // Add height if provided
  if (height) {
    parsedUrl.searchParams.append('h', height.toString());
  }

  // Add format parameter
  parsedUrl.searchParams.append('fm', 'webp');

  // Add quality parameter
  parsedUrl.searchParams.append('q', '74');

  return parsedUrl.toString();
};
